<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text label="평가명" name="assessmentName" v-model="searchParam.assessmentName" />
        </div>
          <!-- defaultStart="-6M"
          defaultEnd="6M" -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="평가기간"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            :editable="editable"
            label="공종"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            :editable="editable"
            label="업체"
            name="assessVendorId"
            v-model="searchParam.assessVendorId">
          </c-vendor>
        </div>
      </template>
    </c-search-box>
    <c-card title="위험성평가 결과 현황" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-title-slot">
        <font color="#C10015">
          ※ 지도정보는 위치를 표시한 공종/작업만 보입니다.
        </font>
      </template>
      <template slot="card-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" ref="carousel">
            <q-carousel
              v-if="data.maps && data.maps.length > 0"
              class="map-carousel"
              control-type="regular"
              control-color="grey-1"
              control-text-color="blue"
              arrows
              thumbnails
              v-model="data.slide"
              infinite
              @transition="transition"
            >
              <q-carousel-slide 
                v-for="(map, idx) in data.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="q-img__content absolute-full"> 
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate"
                      :y="item.locationYcoordinate"
                      :w="30" 
                      :h="30"
                      :grid="[30, 30]"
                    >
                      <!-- {{idx + 1}} -->
                      <q-icon name="cancel" color="blue" class="blinking"  size='xs' style="bottom: 5px;" />
                      <div class="markImage-abbreviation-parent"
                        :style="{ 
                          width: '227px', 
                          left: getLeft(item),
                          top: getTop(item),
                        }">
                        <div class="markImage-abbreviation" 
                          @mouseover="() => { item.isHover = true }"
                          @mouseleave="() => { item.isHover = false }">
                          <!-- <transition name="mark-list" tag="div">
                            <div v-if="!item.isHover" class="markImage-tran">
                              <q-banner
                                dense 
                                class="markImage-abbreviation-banner">
                                <q-icon name="report_problem" class="text-blue" />
                                <div>
                                  {{item.processName}}
                                </div>
                              </q-banner>
                            </div>
                          </transition> -->
                          <transition name="mark-list" tag="div">
                            <div>
                              <q-banner
                                dense 
                                :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                <template v-slot:avatar>
                                  <q-icon name="report_problem" class="text-blue" size='xs'/>
                                </template>
                                <div class="caption-header">
                                  {{item.processName + '/' +item.sopName}}
                                </div>
                                <div class="caption-work">
                                  {{ '업체 : ' + item.assessVendorName }}
                                </div>
                                <div class="caption-work">
                                  {{ '조치/부적합 : ' + item.improveCount + '/' +item.resImproveCount }}
                                </div>
                              </q-banner>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </VueDraggableResizable>
                  </div>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="지도에 등록된 위험성평가가 없습니다."></el-empty>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
            <c-table
              ref="table"
              title="위험성평가 결과 목록"
              :isTitle="false"
              tableId="saasRiskAssess"
              :columns="grid.columns"
              :data="tempMapDetails"
            >
            </c-table>
          </div>
        </template>
      </template>
    </c-card>
    
    <c-dialog :param="popupOptions"></c-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'risk-assess-result-status',
  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentName: '',
        processCd: '',
        assessVendorId: '',
        assessmentDates: [],
      },
      mapMessage: '',
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'groupMdmSopId' },
        ],
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'center',
            style: 'width:160px',
            sortable: false,
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            style: 'width:190px',
            sortable: true
          },
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해·위험요인',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선(건)',
            align: 'center',
            child: [
              {
                name: 'improveCount',
                field: 'improveCount',
                label: '조치완료',
                align: 'center',
                style: 'width:80px',
                sortable: true,
              },
              { 
                name: 'resImproveCount',
                field: 'resImproveCount',
                label: '잔여부적합',
                align: 'center',
                style: 'width:80px',
                sortable: true,
              },
            ]
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      tempMapDetails: [],
      saveUrl: '',
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      ratio: 0,
      sopMapId: '',
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 1.1
    },
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.saas.resultStatus.list.url;
      if (this.$store.getters.user.innerFlag === 'N') { 
        this.searchParam.assessVendorId = this.$store.getters.user.deptCd;
      }
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting
      
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      this.getList();
    },
    getList() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.data.maps = _result.data
        if (this.data.maps && this.data.maps.length > 0) {
          this.tempMapDetails = this.data.maps[this.data.slide].maps;

          this.$_.forEach(this.tempMapDetails, _item => {
            if (_item.workStartDt && _item.workEndDt) {
              _item.workDt = [_item.workStartDt, _item.workEndDt]
            }
          })
          this.$refs['carousel'].style.setProperty("--map-ratio", this.data.maps[0].ratio);
        } else {
          this.tempMapDetails = [];
        }
      },);
    },
    onDrag: function (x, y, item) {
      item.locationXcoordinate = x
      item.locationYcoordinate = y

      if (item.editFlag !== 'C') {
        item.editFlag = 'U'
        item.chgUserId = this.$store.getters.user.userId
      }
    },
    getWidth() {
      let width = 0;
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + 260;
      let detailLocation = item.locationXcoordinate + 260 
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate;
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return top + 'px'
    },
    transition() {   
      this.tempMapDetails = this.data.maps[this.data.slide].maps;

      this.ratio = this.data.maps[this.data.slide].ratio
      this.sopMapId = this.data.maps[this.data.slide].sopMapId
    },
    openRiskAssess(row) {
      this.popupOptions.title = '위험성평가 상세'; // 위험성평가 상세
      this.popupOptions.param = {
        riskAssessmentPlanId: row ? row.riskAssessmentPlanId : '',
        stepCd: row ? row.stepCd : 'RAS000001',
      };
      this.popupOptions.target = () => import(`${'./riskAssessDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 
.vdr 
  border: 3px dashed #000

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: blue
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      // cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        padding: 1px !important
.caption-work
  font-size: 0.5em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.75em
  font-weight: bald
  color: #555

.map-carousel
  height: 700px !important
  .q-carousel__slide
    width: 800px !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
      width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>
<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>